$baseFont: "Roboto", sans-serif;
$bgSecondary: #342d6e;
$white: #fff;
$btnSucess: #00d285;
$primaryColor: #9b91fe;
$purple: #7668fe;
$textDark: #495463;
$hintSecondary: #6b61be;
$navBg: #242650;
$linkcolor: #5e55b8;
$greyColor: #d2dde9;
$baseColor: #eef2f8;
$textColor: #6e81a9;
