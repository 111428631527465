.ico-stage-card {
  .stage-card {
    position: relative;
    border-radius: 4px;
    border: 1px solid #d2dde9;
    transition: all 0.4s;
    margin-bottom: 0;
  }
  .card-head {
    // padding-bottom: 0;
    h4.card-title {
      margin-bottom: 0;
    }
  }
  .stage-title {
    padding: 25px 25px 0;
    margin-bottom: 0;
    @media (max-width: 767px) {
      padding: 15px 15px 0;
      font-size: 14px;
    }
    h6 {
      text-transform: uppercase;
      color: #9fabb7;
      margin-bottom: 0;
      letter-spacing: 0.1em;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      font-size: 0.93em;
    }
    h4 {
      color: #495463;
      margin-bottom: 0;
      font-size: 1.29em;
      font-weight: normal;
    }
    .badge {
      margin-left: 10px;
      padding: 2px 8px;
      font-size: 11px;
      line-height: 15px;
      text-align: center;
      border-radius: 3px;
      font-weight: 500;
      color: #fff;
      border: 1px solid;
      min-width: 60px;
      vertical-align: middle;
    }
  }
  .stage-info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6effb;
    padding: 25px;
    @media (max-width: 767px) {
      padding: 15px;
    }
    &:last-child {
      border-bottom: none;
    }

    .progress {
      width: 80px;
      height: 80px !important;
      float: left;
      background: none;
      margin: 0 0;
      box-shadow: none;
      position: relative;
      background: transparent;
    }

    .progress:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 12px solid #fff;
      position: absolute;
      top: 0;
      left: 0;
    }

    .progress > span {
      width: 50%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 1;
    }

    .progress .progress-left {
      left: 0;
    }

    .progress .progress-bar {
      width: 100%;
      height: 100%;
      background: none;
      border-width: 5px;
      border-style: solid;
      position: absolute;
      top: 0;
    }

    .progress .progress-left .progress-bar {
      left: 100%;
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
    }

    .progress .progress-right {
      right: 0;
    }

    .progress .progress-right .progress-bar {
      left: -100%;
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      animation: loading-1 1.8s linear forwards;
    }

    .progress .progress-value {
      width: 100%;
      /* height: 90%; */
      border-radius: 50%;
      font-size: 20px;
      color: #7668fe;
      /* line-height: 75px; */
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .progress.blue .progress-bar {
      border-color: #2b56f5;
    }

    .progress.blue .progress-left .progress-bar {
      animation: loading-2 1.5s linear forwards 1.8s;
    }

    .progress.yellow .progress-bar {
      border-color: #fdba04;
    }

    .progress.yellow .progress-right .progress-bar {
      animation: loading-3 1.8s linear forwards;
    }

    .progress.yellow .progress-left .progress-bar {
      animation: none;
    }

    @keyframes loading-1 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    @keyframes loading-2 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
      }
    }

    @keyframes loading-3 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
      }
    }
    .stage-info-txt {
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
      h6 {
        margin-bottom: 3px;
        color: #9fabb7;
        font-size: 0.93em;
        font-weight: 300;
      }
      .stage-info-number {
        color: #495463;
        margin-bottom: 0;
        font-size: 1.25em;
        small {
          font-size: 0.6em;
          color: #7668fe;
          margin-left: 4px;
        }
      }

      .stage-info-total {
        color: #7668fe !important;
        margin-bottom: 2px;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
      .stage-info-count {
        font-size: 12px;
        color: #758698;
        span {
          color: #495463;
          font-weight: 700;
        }
      }
    }
  }
  .progress-status {
    font-size: 12px;
    color: #758698;
    line-height: 1.3;
    display: block;
  }
  .price-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h5 {
      margin-bottom: 0;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}
