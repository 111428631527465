@import "../../../assets/theme/clr_var.scss";
@import "../../../assets/theme/_mixins.scss";

.tile-header {
  position: relative;
  margin-bottom: 9px;
  .tile-title {
    font-size: 12px;
    font-weight: 500;
    color: $textColor;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}
.tile-data-number {
  font-size: 24px;
  line-height: 1;
  color: #495463;
  font-weight: 500;
  @media (min-width: 576px) {
    font-size: 32px;
  }
}
.tile-data-status {
  margin: 0 12px;
  position: relative;
  color: #1babfe;
  background: rgba(27, 171, 254, 0.15);
  font-size: 14px;
  line-height: 20px;
  padding: 5px 14px;
  border-radius: 15px;
}
.tile-footer {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.tile-recent-number {
  font-size: 16px;
  color: $textColor;

  display: inline-block;
  margin-right: 3px;
  font-weight: 700;
  @media (min-width: 576px) {
    font-size: 18px;
  }
}
.tile-recent-text {
  color: $textColor;

  font-size: 13px;
  font-weight: 400;
}
.link-dim {
  opacity: 0.7;
}
.link-ucap {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.link-thin {
  font-weight: 400;
}
.link {
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  transition: all 0.2s;
}
.tile-nav {
  position: absolute;
  z-index: 1;
  top: 18px;
  right: 25px;
  border-bottom: none !important;
  li {
    .nav-link {
      line-height: 17px;
      padding: 2px 7px 2px 9px;
      font-size: 11px;
      font-weight: 500;
      border-radius: 11px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      color: $textColor;
      border: none;
      &.active {
        color: #758698;
        background: rgba(117, 134, 152, 0.15);
      }
    }
  }
}
.darkCard {
  ul {
    display: block;
  }
  .tile-title {
    color: #ffe969;
  }
  .balance-text {
    .lead {
      color: $white;
      font-size: 1rem;
      font-weight: 500;
      @media (max-width: 767px) {
        font-size: 14px;
      }
      span {
        color: rgba(255, 255, 255, 0.7);
        display: block;
        line-height: 0.9;
        font-size: 12px;
      }
    }
  }
  .tile-nav {
    li {
      .nav-link {
        color: $white;

        &.active {
          background: rgba(255, 255, 255, 0.15);
        }
      }
    }
  }
  .balance-list {
    padding: 0;
    display: flex;
    margin-bottom: 0;
    li {
      list-style: none;
      min-width: 33.33%;
      .lead {
        font-size: 1.1em;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.02em;
      }
      .sub {
        display: block;
        line-height: 0.9;
        font-size: 11px;
      }
    }
  }
}
.card-head {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 18px;
  .card-title-sm {
    font-size: 1.09em;
    padding-top: 2px;
  }
  .link {
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    transition: all 0.2s;
    text-transform: uppercase;
    box-icon {
      width: 18px;
      fill: $purple;
    }
  }
}
.text-right {
  text-align: right !important;
}
.tnx-table {
  td {
    border: none;
    padding: 10px 0 !important;
  }
  .lead {
    font-size: 14px;
    font-weight: 600;
    color: $textDark;
    letter-spacing: 0.01em;
    line-height: 1;
    margin-top: 0;
    margin-right: 4px;
  }
  .sub {
    font-size: 12px;
    font-weight: 400;
    color: #758698;
    letter-spacing: 0.01em;
    line-height: 1;
    padding-top: 7px;
  }
  .data-state-progres {
    border: 1px solid #1babfe;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-icon {
      fill: #1babfe;
    }
    &.cancel {
      border-color: #ff6868;
      box-icon {
        fill: #ff6868;
      }
    }
  }
}
.line-sale-graph {
  height: 260px;
}
.pd-15px {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.chart-phase {
  position: relative;
  height: 190px;
  width: 190px;
  canvas {
    position: relative;
    z-index: 2;
  }
}
.chart-sale-s2 {
  height: 100%;
  width: 100%;
}
.chart-col {
}

.phase-status-total {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding-top: 15px;
  .lead {
    font-weight: 500;
    font-size: 20px;
    color: #495463;
    line-height: 1;
  }
  .sub {
    font-size: 12px;
    text-transform: uppercase;
    color: #758698;
    display: block;
  }
}
.pahse-block {
  align-items: center;
  margin-left: -10px !important;
  margin-right: -10px !important;

  @media (min-width: 576px) {
    display: flex;
  }
}
.phase-status {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  li {
    position: relative;
    width: 50%;
    padding-left: 24px;
    padding-right: 15px;
    margin-bottom: 20px;
    list-style: none;
    &::before {
      position: absolute;
      height: 16px;
      width: 16px;
      border-radius: 3px;
      content: "";
      top: 0;
      left: 0;
    }
    &:nth-child(1):before {
      background: #1babfe;
    }
    &:nth-child(2):before {
      background: #00d285;
    }
    &:nth-child(3):before {
      background: #bc69fb;
    }
    &:nth-child(4):before {
      background: #ffc100;
    }
    .phase-status-title {
      font-size: 14px;
      color: #495463;
      line-height: 18px;
      font-weight: 500;
    }
    .phase-status-subtitle {
      padding-top: 3px;
      font-size: 13px;
      color: #6e81a9;
      line-height: 1.2;
    }
  }
}
.notes {
  font-size: 12px;
  color: #758698;
}
