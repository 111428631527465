@import "../../assets/theme/clr_var.scss";
@import "../../assets/theme/_mixins.scss";
@-webkit-keyframes delay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.05);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes delay {
  0%,
  40%,
  100% {
    transform: scaleY(0.05);
    -webkit-transform: scaleY(0.05);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.overlayloader {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 100000;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);

  .loader {
    margin: 0 auto;
    width: 100px;
    height: auto;
    text-align: center;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    img {
      width: 100px;
      animation: shake 200ms infinite;
      animation-timing-function: linear;
    }
  }
}
@keyframes shake {
  0% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(3deg);
  }
}
.spinner-border {
  animation: rotate 1s linear infinite;
  background: transparent;
  border-radius: 50%;
  height: 80px !important;
  width: 80px !important;
  position: relative;
  margin-top: 55px;
}
