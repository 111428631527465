.btn-primary {
  background-color: #7668fe !important;
  border-color: #7668fe !important;
  &:hover,
  &:focus {
    color: #fff;
    background: #5645fe !important;
    border-color: #5645fe !important;
  }
}
.custom-btn {
  .btn {
    margin-top: 20px;
    padding: 8px 20px;
    font-size: 14px;
    line-height: 24px;
  }
}
