.page-nav-wrap {
  border: 1px solid #e6effb;
  border-radius: 3px;
  margin-bottom: 30px;
  .page-nav-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 20px;
    @media (max-width: 767px) {
      padding: 8px 10px;
    }
  }
  .page-nav li {
    &:first-child a {
      padding-left: 0;
    }
    p {
      font-size: 14px;
      color: #758698;
      font-weight: 500;
      padding: 0.5rem 1rem;
      margin-bottom: 0;
      @media (max-width: 767px) {
        padding: 0 5px;
      }
      &.is-active {
        color: #7668fe;
      }
    }
  }
}
.bg-lighter {
  background: #f9fcff !important;
}
.pl-lg-4,
.px-lg-4 {
  padding-left: 1.5rem !important;
  @media (max-width: 767px) {
    padding-left: 0 !important;
  }
}

.input-wrap {
  position: relative;

  .input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    opacity: 0.5;
    line-height: 1;
    font-size: 12px;
    margin-top: 2px;
  }

  .input-solid {
    border-radius: 4px;
    border: none;
    width: 100%;
    padding: 10px 15px;
    line-height: 20px;
    font-size: 0.9em;
    color: #6e81a9;
    background: #eef2f8;
    border: 1px solid #eef2f8;
    transition: all 0.4s;
    padding-left: 40px;

    &.input-transparent {
      background: transparent;
      border: none;
    }
    &:focus {
      box-shadow: unset;
    }
  }
}
.file-icon {
  color: #758698;
  border: 1px solid #c9cfd6;
  padding: 7px 10px;
  font-size: 14px;
  letter-spacing: 0.01em;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  background: #fff;
}
