.card-title {
  color: #342d6e;
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  @media (min-width: 576px) {
    font-size: 1.4em;
  }
  small {
    font-size: 80%;
    font-weight: 400;
  }
}
