@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

@import "./assets/theme/clr_var.scss";
@import "./assets/theme/_mixins.scss";
html,
body {
  height: 100%;
}
body {
  font-family: "Roboto", sans-serif !important;
  background: $baseColor !important;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  .container {
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }
  button,
  a {
    &:hover,
    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }
  a:link,
  a:visited {
    text-decoration: none;
  }

  a {
    outline: 0;
    transition: all 0.5s;
    color: #5e55b8;
  }
}
div#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
