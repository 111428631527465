@import "../../assets/theme/clr_var.scss";
@import "../../assets/theme/_mixins.scss";

.custom-input {
  .form-control {
    border-radius: 4px;
    border: 1px solid $greyColor;
    width: 100%;
    padding: 10px 15px;
    line-height: 20px;
    font-size: 0.9em;
    color: rgba(73, 84, 99, 0.7);
    transition: all 0.4s;
    &:focus {
      box-shadow: 0 1px 5px rgba(73, 84, 99, 10%);
      outline: none;
      border-color: #b1becc;
      color: #495463;
    }
  }
  span.danger {
    color: red;
    font-size: 12px;
    border-color: red;
  }
}
