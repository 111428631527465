@import "../../assets/theme/clr_var.scss";
@import "../../assets/theme/_mixins.scss";

.subheader {
  background: $white;
  box-shadow: 0 1px 3px rgba(24, 30, 43, 7%);

  &.navbar {
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-nav {
    a {
      padding: 12px 0;
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 32px;
      position: relative;
      color: $purple;
      border-bottom: 2px solid transparent;
      margin: 0 28px 0 0;
      @media (max-width: 991px) {
        line-height: 20px;
        margin: 0;
      }
      &.active {
        border-bottom: 2px solid $purple;
      }
      &:hover {
        border-bottom: 2px solid $purple;
      }
      box-icon {
        width: 22px;
        height: 22px;
        margin-right: 4px;
        margin-left: -3px;
        fill: $purple;
      }
    }
  }
  .clear-cache-btn {
    color: #495463;
    border-color: $greyColor;
    padding: 4px 11px 4px;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 19px;
    letter-spacing: 0.025em;
    display: flex;
    align-items: center;
    color: #495463;
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
    &:hover {
      background: transparent;
      border-color: $greyColor;
      color: #495463;
    }
    box-icon {
      width: 14px;
      height: 14px;
      fill: #495463;
      margin-right: 12px;
    }
  }
}
.header-wrap {
  margin-bottom: 50px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}
.navbar-light .navbar-toggler {
  @media (max-width: 991px) {
    position: absolute;
    top: -50px;
    z-index: 9999;
    left: -5px;
    border-color: transparent !important;
  }
}
.navbar-brand {
  @media (max-width: 991px) {
    margin-left: 20px;
  }
}
.navbar-light .navbar-toggler-icon {
  @media (max-width: 991px) {
    filter: invert(1);
    width: 20px;
  }
}
