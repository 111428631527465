.topbar.bg-light {
  background-color: transparent;
}
.topbar {
  width: 100%;
  padding: 30px 45px 0 !important;

  background-color: transparent;
  border-bottom-color: transparent;
  @media (max-width: 991px) {
    border-bottom: 1px solid rgba(118, 104, 254, 0.25);
    background: #342d6e;
    padding: 5px !important;
  }
  img {
    max-width: 180px;
    max-height: 90px;
    @media (max-width: 991px) {
      max-width: 90px;
    }
  }
  .header-btn {
    min-width: 150px;
    cursor: pointer;
    padding: 10px 25px;
    text-decoration: none;
    font-size: 14px;
    background: #ff6868;
    border-color: #ff6868;
    color: #fff;
    transition: all 0.4s ease;
    line-height: 24px;
    letter-spacing: 0.01em;
    border-radius: 4px;
    text-align: center;
    &:hover {
      color: #fff;
      background: #ff4f4f;
      border-color: #ff4f4f;
    }
  }
}
