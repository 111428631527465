@import "../../assets/theme/clr_var.scss";
@import "../../assets/theme/_mixins.scss";

.page-intro-title {
  font-size: 1.75rem;
  font-weight: 700;
  padding-bottom: 25px;
  margin-bottom: 12px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 1.5rem;
  }

  span {
    display: block;
  }
}

.page-intro-side {
  display: flex;
  align-items: center;
  flex-direction: column;

  justify-content: center;
  @media (min-width: 992px) {
    padding: 50px;
    min-height: 100vh;
  }
  @media (max-width: 576px) {
    min-height: 50vh;
  }
  @media (max-width: 991px) {
    padding: 30px 0;
  }
}
.top-margin {
  @media (max-width: 991px) {
    margin-top: 76px !important;
  }
}
.bg_secondary {
  background: $bgSecondary;
}
.text-white {
  color: $white;
}
.fs-14 {
  font-size: 14px;
}
.page-intro-title small {
  font-weight: 500;
  border-top: 1px solid #d2dde9;
  padding-top: 12px;
  margin-top: 12px;
  display: inline-block;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
p.lead {
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 12px;
  text-align: center;
}
.bg_primary {
  background: $white;
}
.page-intro-side {
  .btn-lg {
    padding: 15px 20px;
    min-width: 220px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    margin-top: 40px;
  }
  .hint-primary {
    color: $primaryColor;
  }
  .hint {
    font-size: 0.9rem;
    margin-top: 20px;
  }
  .secondary-title {
    color: $purple;
  }
}
.text_dark {
  color: $textDark;
}
.btn_primary {
  background: $purple;
}
.btn_secondary {
  background: $btnSucess;
}
.hint-secondary {
  color: $hintSecondary;
}
.page-intro-notice {
  margin-top: 35px;

  @media (min-width: 992px) {
    position: absolute;
    left: 50%;
    bottom: 6vh;
    transform: translateX(-50%);
    border-radius: 25px;
    padding: 10px 25px;
    color: #fff !important;
    background: rgba(118, 104, 254, 0.85);
    text-align: center;
  }
  p {
    margin-bottom: 0;
    color: #fff !important;
  }
  a {
    color: #fff !important;
  }
}
