.card-sub-title {
  text-transform: uppercase;
  color: #758698;
  letter-spacing: 0.07em;
  display: block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 4px;
}
.data-details-list {
  border-radius: 4px;
  border: 1px solid #d2dde9;
  li {
    @media (min-width: 576px) {
      display: flex;
      align-items: center;
    }
    .data-details-head {
      font-size: 14px;
      font-weight: 500;
      color: #758698;
      line-height: 20px;
      padding: 14px 20px;

      @media (min-width: 576px) {
        width: 190px;
      }
    }
    .data-details-des {
      font-size: 14px;
      color: #495463;
      font-weight: 400;
      line-height: 20px;
      padding: 2px 20px 15px;
      flex-grow: 1;
      border-bottom: 1px solid #d2dde9;
      display: flex;
      justify-content: space-between;

      @media (min-width: 576px) {
        border-top: none;
        border-left: 1px solid #d2dde9;
        width: calc(100% - 190px);
        padding: 14px 20px;
      }
    }
  }
}
