@import "../../../assets/theme/clr_var.scss";
.user-table {
  .verified-check {
    box-icon {
      fill: #00d285;
      border-color: #00d285;
      width: 24px;
      margin-right: 5px;
    }
  }
  .kyc-icon {
    font-size: 20px;
    color: #b1becc;
    margin: 0 10px;
  }

  .dot-icon {
    box-icon {
      fill: #495463;

      //   width: 14px;
      //   height: 14px;
    }
  }
}
.status-col {
  .dropstart {
    .btn-secondary {
      padding: 4px 6px 4px;
      background: #e6effb;
      border-color: #e6effb;
      letter-spacing: 0.025em;
      margin-left: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 29px;
      border-radius: 3px;
      min-width: 32px;
      &::before {
        content: " ";
        border: none;
        color: #495463;
        background: url(../../../assets/images/dots.png);
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
    .dropdown-menu {
      position: absolute;
      background: #fff;
      border-radius: 4px;
      z-index: 999;
      box-shadow: 0px 0 35px 0px rgba(0, 0, 0, 20%);
      border: none;
      min-width: auto;
      padding: 14px 15px;

      &::after {
        top: 8px;
        left: auto;
        right: -14px;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #fff;
        position: absolute;
        content: "";
        margin-left: -7px;
        height: 0;
        width: 0;
        border-right: 7px solid transparent;
      }

      a {
        position: relative;
        text-align: left;
        padding: 6px 32px 6px 16px;
        white-space: nowrap;
        color: #495463;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(230, 239, 251, 0.5);
      }
    }
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #342d6e;
    text-decoration: none;
    background-color: transparent;
  }
}
