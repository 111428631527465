@import "./assets/theme/_mixins.scss";
@import "./assets/theme/clr_var.scss";

.publicLayout {
  height: 100%;
}

body iframe {
  display: none;
}
.page-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  .card {
    border: none;
    margin-bottom: 30px;
    border-radius: 4px;
    background: $white;
    transition: 0.4s, border-color 0.4s, color 0.4s;
    vertical-align: top;
  }
  .card-body {
    padding: 20px 25px;
  }
  .darkCard {
    background-image: linear-gradient(45deg, #5e55b8 0%, #7668fe 100%);
    color: $white;
  }
  h5,
  .h5 {
    font-size: 1.07em;
  }
  h1,
  h2,
  h4,
  h5 {
    color: #342d6e;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
  }
}
.gaps-3x {
  height: 30px;
}
