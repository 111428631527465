.page-ath-form {
  width: 440px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 30px;
  .page-ath-heading {
    padding-bottom: 23px;
    font-size: 2.8em;
    font-weight: 300;
    letter-spacing: -0.025em;
    color: #342d6e;
    line-height: 1.2;
    @media (max-width: 767px) {
      font-size: 1.8em;
      padding-bottom: 0;
      margin-bottom: 12px;
    }
    small {
      display: block;
      font-weight: 400;
      font-size: 0.66em;
      color: #495463;
      letter-spacing: normal;
      margin-top: 10px;
      font-size: 0.46em;
      @media (max-width: 767px) {
        font-size: 0.66em;
      }
    }
    span {
      display: block;
      font-weight: 400;
      font-size: 0.41em;
      color: #495463;
      letter-spacing: normal;
      line-height: 1.62;
      padding-top: 15px;
    }
  }
}
