.trans-col {
  //   display: flex;
  //   align-items: center;
}
.mr-10 {
  margin-right: 10px;
}
.sub-s2 {
  font-size: 13px;
  color: #495463;
  letter-spacing: 0.02em;
  font-weight: 400;
}
.data-col {
  padding: 10px 15px 10px 0 !important;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #7668fe;
  text-transform: uppercase;
  border-bottom: none;
}
.table > :not(:first-child) {
  border-top: none !important;
}
.tnx-table {
  thead {
    tr {
      border-bottom: none;
    }
  }
}
.data-item {
  border-bottom: 1px solid #e6effb !important;
  .data-td {
    padding: 14px 15px 14px 0 !important;
  }
}
.all-tranx-table {
  vertical-align: middle !important;
  .sub {
    display: flex;
    align-items: center;
    box-icon {
      width: 13px;
      fill: #758698;
      margin-left: 5px;
    }
  }
  .data-type {
    .btn {
      padding: 6px 12px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      min-width: 90px;
      border-color: #00d285;
    }
  }
  .on-hold {
    color: #ffc100;
    border-color: #ffc100;
  }
}
.pagination-bar {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  margin-bottom: 5px;
  .btn {
    background: #e6effb;
    border-color: #e6effb;
    font-size: 14px;
    text-transform: uppercase;
    @media (max-width: 767px) {
      font-size: 10px;
      padding: 5px 7px;
    }
  }
  .pagination-info-text {
    font-size: 13px;
    color: #758698;
    padding: 0 5px;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
}
@media (max-width: 767px) {
  tbody,
  tr,
  tr > td,
  tr > th {
    width: auto !important;
    display: block !important;
  }
  tr > td {
    display: flex !important;
    justify-content: space-between;
    border-bottom: 1px solid #eee !important;
  }
  table.table thead {
    display: none !important;
  }
  .table tbody td:before {
    content: attr(data-th);
    font-weight: bold;
    display: inline-block;
    width: 6.5em;
    font-size: 12px;
  }
}
