@import "../../assets/theme/clr_var.scss";
@import "../../assets/theme/_mixins.scss";
.main-header {
  background: $navBg;
  .logo {
    max-width: 100px;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown {
    align-items: center;
  }
  .user-welcome {
    display: inline-block;
    margin-right: 10px;
    color: $white;
  }
  .user-thumb {
    display: inline-block;
    height: 32px;
    width: 32px;
    line-height: 30px;
    fill: $white;
    border-radius: 50%;
    background: $purple;
    text-align: center;
    margin: 0 auto;
    padding: 3px 0;
    box-icon {
      width: 15px;
      height: 15px;
    }
  }
}
